<template>
  <v-container>
    <v-row class="justify-center" v-if="!isSuccess">
      <v-card
        class="mx-auto justify-center px-5 py-5 mx-auto"
        outlined
        id="card-form"
        max-width="374"
      >
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="form.new_password"
            label="new password"
            :counter="6"
            :rules="passwordRules"
            outlined
            required
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :min="6"
          ></v-text-field>

          <v-text-field
            v-model="form.confirm_password"
            label="confirm password"
            :counter="6"
            :rules="confirmPasswordRules"
            outlined
            required
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            :min="6"
          ></v-text-field>

          <v-btn class="mr-4" @click="submit" :disabled="!isSubmit">
            submit
          </v-btn>
        </v-form>
      </v-card>
    </v-row>

    <v-row>
      <v-card>
        <v-dialog v-model="dialog" width="700">
          <v-card>
            <div v-if="isSuccess">
              <v-card-title class="text-h5 grey lighten-2">
                Reset is success !
              </v-card-title>
              <v-card-text>
                Reset Password is Success, please sign in to main app !.
              </v-card-text>
            </div>
            <div v-else>
              <v-card-title class="text-h5 grey lighten-2">
                Reset is failed !
              </v-card-title>

              <v-card-text>
                Reset Password is failed, please contact your admin or request
                to reset password again !.
              </v-card-text>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SetPassword',

  data: () => ({
    form: {
      new_password: '',
      confirm_password: '',
    },
    passwordRules: [
      (v) => !!v || 'password is required',
      (v) => (v && v.length >= 6) || 'minimum 6 character',
    ],
    confirmPasswordRules: [
      (v) => !!v || 'confirm password is required',
      (v) => (v && v.length >= 6) || 'minimum 6 character',
    ],
    showPassword: false,
    showConfirmPassword: false,
    token: '',
    isSubmit: false,
    baseUrl: '',
    dialog: false,
    isSuccess: false,
  }),

  methods: {
    async submit() {
      this.validate();
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(this.form),
      });

      if (response.status === 201) {
        this.isSuccess = true;
        this.dialog = true;
      } else {
        this.dialog = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    setBaseConfig() {
      (this.token = this.$route.query.token),
        (this.baseUrl =
          window.location.origin + this.$route.path + '/auth/reset-password');
    },
  },

  created() {
    this.setBaseConfig();
  },

  watch: {
    'form.confirm_password': function (val) {
      if (val === this.form.new_password && val.length >= 6) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
  },
};
</script>

<style scoped>
#card-form {
  margin-top: 50px;
}
</style>
