<template>
  <v-app>
    <v-main>
      <set-password />
    </v-main>
  </v-app>
</template>

<script>
import SetPassword from './components/SetPassword.vue';

export default {
  name: 'App',

  components: {
    SetPassword,
  }
};
</script>
